


























import { Component, Vue, Prop } from 'vue-property-decorator'
import moment from 'moment'
import * as xlsx from 'xlsx'
import { de } from '../i18n/language.de'

moment.locale('de', {
  months : 'Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember'.split(',')
})

import Header from '@/components/Header.vue'
import ContainerCard from '@/components/ContainerCard.vue'

import { IInvoice } from '@/graphql/companyService/interfaces/Invoices.interface'
import { cloudFunction } from '@/lib/parse-service'
// @ts-ignore
import { parse as parseToCsv } from 'json2csv'

// https://stackoverflow.com/a/45287523
// @ts-ignore
function renameKeys(obj, newKeys) {
  // @ts-ignore
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

function fileFromData(data: any, type: string, filename: string): void {
    const blob = new Blob([data], { type })
    const anchor = document.createElement('a')
    anchor.download = filename
    anchor.href = (window.webkitURL || window.URL).createObjectURL(blob)
    anchor.dataset.downloadurl = [type, anchor.download, anchor.href].join(':')
    anchor.click()
}

@Component<InvoiceView>({
  components: {
    Header,
    ContainerCard
  },
  // @ts-ignore
  head: {
    title () {
      return {
        inner: `${this.$t('invoice.hint')}`
      }
    }
  }
})
export default class InvoiceView extends Vue {
  invoices: IInvoice[] = []

  mounted() {
    this.getInvoices()
  }

  async getInvoices() {
    const { result } = await cloudFunction('getInvoiceList')
    this.invoices = result
    this.$forceUpdate()
  }

  isHideExcel () { 
    return process.env.VUE_APP_FEATURE_HIDE_EXCEL === "1" || process.env.VUE_APP_FEATURE_HIDE_EXCEL === "true"
  };

  isHideSepa () {
    return process.env.VUE_APP_FEATURE_HIDE_SEPA_XML === "1" || process.env.VUE_APP_FEATURE_HIDE_SEPA_XML === "true"
  }

  getMonth(invoice: IInvoice) {
    return moment(invoice.from).format('MMMM')
  }

  getYear(invoice: IInvoice) {
    return moment(invoice.from).format('YYYY')
  }

  getAccountingPeriod(invoice: IInvoice) {
    return `${moment(invoice.from).format('DD.MM.YYYY')} – ${moment(invoice.to).format('DD.MM.YYYY')}`
  }

  downloadxls(data: any, filename: string) {
    // @ts-ignore
    let ws = xlsx.utils.json_to_sheet(data.map(({ paymentInfo, ...e }) => e));
    let wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
    xlsx.writeFile(wb, `${filename}.xlsx`);
  }

  async downloadInvoice(invoice: IInvoice, type: string = 'csv' ) {
    try {
      const { result } = await cloudFunction('downloadInvoice', invoice)
      // @ts-ignore
      const invoiceData = result.map((e) => ({ ...e, 
        fare:  e.fare.toFixed(2).replace(".", ","),
        payout: e.payout.toFixed(2).replace(".", ","),
        payoutLimit: e.payoutLimit ? e.payoutLimit.toFixed(2).replace(".", ",") : undefined,
      }));
    
      if (type === 'xlsx') {
        // @ts-ignore
        this.downloadxls(invoiceData.map(e => renameKeys(e, de.invoice.documentLabel)), `invoice-${this.getAccountingPeriod(invoice).replaceAll(' ','_')}`)
        return;
      }
      // @ts-ignore
      const opts = { fields: Object.entries(de.invoice.documentLabel).map(([value, label]) => ({ label, value })) }
      // @ts-ignore
      const csv = parseToCsv(invoiceData, opts)
      // @ts-ignore
      fileFromData(csv, 'text/csv', `invoice-${this.getAccountingPeriod(invoice).replaceAll(' ','_')}.csv`)
    } catch (err) {
      console.error('Failed to download', err)
    }
  }

  async downloadInvoiceSummary(invoice: IInvoice, type: string = 'csv') {
    try {

      if (type === 'sepa') {
        const { result } = await cloudFunction('downloadInvoiceSummarySepaXml', invoice);
        return fileFromData(result, 'text/xml', `invoice-summary-sepa-${this.getAccountingPeriod(invoice).replaceAll(' ','_')}.xml`)
      }

      const { result } = await cloudFunction('downloadInvoiceSummary', invoice)
      // @ts-ignore
      const documentData = result.map(e => ({ ...e, payout: e.payout.replace(".", ","), fare: e.fare.replace(".", ","), }))
      if (type === 'xlsx') {
        // @ts-ignore
        return this.downloadxls(documentData.map(e => renameKeys(e, de.invoice.summaryDocumentLabel)), `invoice-summary-${this.getAccountingPeriod(invoice).replaceAll(' ','_')}`)
      }
      // @ts-ignore
      const opts = { fields: Object.entries(de.invoice.summaryDocumentLabel).map(([value, label]) => ({ label, value })) }
      // @ts-ignore
      const csv = parseToCsv(documentData, opts)
      // @ts-ignore
      fileFromData(csv, 'text/csv', `invoice-summary-${this.getAccountingPeriod(invoice).replaceAll(' ','_')}.csv`)
    } catch (err) {
      console.error('Failed to download', err)
    }
  }
}
